@import "./variables";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $backgroundColor;
  color: $fontColor;
  max-width: 1440px;
  margin: 0 auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.fab,
.fas {
  padding-right: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  color: black;
  &:hover {
    opacity: 0.5;
  }
}

#root {
  margin: 0 auto;
  max-width: 95%;
}

.aem-logo {
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url("./assets/aem-logo.png") no-repeat center;
  background-size: contain;
  vertical-align: middle;
  filter: brightness(0.9);
  transition: filter 0.3s ease;

  &:hover {
    filter: brightness(1);
  }
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.mainContent {
  flex: 1;
  position: relative;
}
