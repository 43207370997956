@import "../../variables";

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
}

.spinner {
  border: 3px solid rgba($yellowColor, 0.1);
  border-radius: 50%;
  border-top-color: $yellowColor;
  animation: spin 1s ease-in-out infinite;

  &--small {
    width: 24px;
    height: 24px;
    border-width: 2px;
  }

  &--medium {
    width: 40px;
    height: 40px;
    border-width: 3px;
  }

  &--large {
    width: 56px;
    height: 56px;
    border-width: 4px;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

// Reduced motion
@media (prefers-reduced-motion: reduce) {
  .spinner {
    animation-duration: 1.5s;
  }
}
