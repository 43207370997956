@import "../../variables";

.footerWrapper {
  width: 100%;
  margin-top: 4rem;
  position: relative;
  z-index: 10;
}

.footer {
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
  position: relative;
  padding: 2rem 0;
  border-top: 1px solid rgba(#2b5876, 0.1);
  backdrop-filter: blur(10px);

  // Modern top border effect
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(#2b5876, 0.3),
      rgba(#4e4376, 0.5),
      rgba(#2b5876, 0.3),
      transparent
    );
    filter: blur(0.5px);
  }

  // Subtle glass morphism effect
  &::after {
    content: "";
    position: absolute;
    inset: 0;
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.8)
    );
    z-index: -1;
  }

  &__content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    position: relative;
    z-index: 1;

    @include breakpoint("md") {
      flex-direction: row;
      justify-content: space-between;
      gap: 3rem;
    }
  }

  &__copyright {
    font-size: 0.95rem;
    font-weight: 500;
    color: #2b5876;
    text-align: center;
    padding: 0.75rem 1.5rem;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 12px;
    border: 1px solid rgba(#4e4376, 0.1);
    box-shadow: 0 2px 10px rgba(#4e4376, 0.05), 0 0 0 1px rgba(#4e4376, 0.05);
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 20px rgba(#4e4376, 0.1), 0 0 0 1px rgba(#4e4376, 0.1);
    }
  }

  &__social {
    display: flex;
    gap: 2rem;
    align-items: center;
    padding: 0.5rem;
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    color: #2b5876;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    background: none;
    border: none;

    i {
      font-size: 1.4rem;
      transition: all 0.3s ease;
    }

    &:hover {
      color: #4e4376;
      transform: translateY(-2px);

      i {
        transform: scale(1.1);
      }
    }
  }
}

// Reduced motion
@media (prefers-reduced-motion: reduce) {
  .footer {
    &__copyright,
    &__link {
      transition: none;

      &:hover {
        transform: none;
        box-shadow: none;
      }

      i {
        transform: none;
      }
    }
  }
}
