// Brand Colors
$yellowColor: #f9ea9a;
$darkerYellowColor: #f3d840;
$fontColor: #28262c;

// UI Colors
$textColor: #333;
$textColorLight: #666;
$primaryColor: #007bff;
$secondaryColor: #6c757d;
$backgroundColor: #fff;
$borderColor: rgba(0, 0, 0, 0.1);

// Breakpoints
$breakpoints: (
  "xs": 320px,
  "sm": 576px,
  "md": 768px,
  "lg": 992px,
  "xl": 1200px,
  "xxl": 1400px,
);

// Mixins for media queries
@mixin breakpoint($point) {
  @if map-has-key($breakpoints, $point) {
    @media (min-width: map-get($breakpoints, $point)) {
      @content;
    }
  }
}

// Layout
$maxWidth: 1200px;
$containerPadding: 2rem;

// Typography
$fontFamily: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
  "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
$fontSize: 16px;
$lineHeight: 1.5;

// Transitions
$transitionSpeed: 0.2s;
$transitionEasing: cubic-bezier(0.4, 0, 0.2, 1);
