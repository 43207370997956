@import "./variables";

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.mainContent {
  flex: 1;
  width: 100%;
  margin-top: 5rem;
  position: relative;
}

// Add styles for the spinner when it appears in main content
.mainContent > :global(.wrapper) {
  margin-top: 5rem;
}
